$darkgreen: #7e2e7a;
$darkblue: #003479;
$lightblue: #00a0df;
.EyebrowHeadlineText {
  /* background-image: url(../../assets/images/header-background.jpg); */
  text-align: left;

  img {
    margin-top: 0;
    // margin-bottom: 0;
    //above 720px
    @media (min-width: 720px) {
      max-width: 100%;
    }
  }
  h3 {
    margin-left: 0;
    font-size: 22px;
  }
  h4 {
    // margin-bottom: 0;
  }
  h5 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }
  .N739 {
    content: url("../../assets/images/discover/N739.png");
    width: 25%;
  }
  .discover-1 {
    content: url("../../assets/images/discover/discover-1.png");
    width: 25%;
  }
  .discover-2 {
    content: url("../../assets/images/discover/discover-2.png");
    width: 25%;
  }
  .N381 {
    content: url("../../assets/images/discover/N381.png");
    width: 25%;
  }

  .Footnotes {
    text-align: left;
    .text {
      font-size: 12px;
    }
  }
  .GraphBullets {
    text-align: left;
    h5 {
      margin-bottom: 1em;
      margin-top: 0;
    }
    //list item bullet points green
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 20px;
    }

    li {
      padding-left: 1em;
      text-indent: -1em;
    }

    li::before {
      content: "• ";
      color: $darkblue; /* or whatever color you prefer */
      zoom: 1.3;
      // vertical-align: sub;
    }
  }

  .WideImageBlock {
    text-align: left;
    margin-bottom: 1rem;
    @media (max-width: 719px) {
      overflow-x: scroll;
    }
    &.img_wrapper_clicked {
      mask-image: none !important;
    }
    .slide-4 {
      content: url("../../assets/images/slide-4.png");
      // width: 100%;
    }
    .slide-5 {
      content: url("../../assets/images/slide-5.png");
      // width: 100%;
    }
    .image-discover-1 {
      content: url("../../assets/images/image-discover-1.jpg");
    }
    .guselkumab-moa-7 {
      content: url("../../assets/images/7-guselkumab-moa.png");
    }
    .guselkumab-moa-8 {
      content: url("../../assets/images/8-guselkumab-moa.png");
    }
    .study-design-11 {
      content: url("../../assets/images/11-study-design.png");
    }
    .study-design-12 {
      content: url("../../assets/images/12-study-design.png");
    }
    .baseline-char-13 {
      content: url("../../assets/images/13-baseline-char.png");
    }
    .response-15 {
      content: url("../../assets/images/15-response.png");
    }
    .response-16 {
      content: url("../../assets/images/16-response.png");
    }
    .response-17 {
      content: url("../../assets/images/17-response.png");
    }
    .response-19 {
      content: url("../../assets/images/19-response.png");
    }
    .patients-experienced-21 {
      content: url("../../assets/images/21-patients-experienced.png");
    }
    .majority-of-patients-22 {
      content: url("../../assets/images/22-majority-of-patients.png");
    }
    .manifestations-24 {
      content: url("../../assets/images/24-manifestations.png");
    }
    .manifestations-25 {
      content: url("../../assets/images/25-manifestations.png");
    }
    .rates-27 {
      content: url("../../assets/images/27-rates.png");
    }
    .rates-28 {
      content: url("../../assets/images/28-rates.png");
    }
    .haq-di-30 {
      content: url("../../assets/images/30-haq-di.png");
    }
    .mda-31 {
      content: url("../../assets/images/31-mda.png");
    }

    .mda-32 {
      content: url("../../assets/images/32-mda.png");
    }
    .baseline-33 {
      content: url("../../assets/images/33-baseline.png");
    }
    .improvement-34 {
      content: url("../../assets/images/34-improvement.png");
    }
    .basdai-36 {
      content: url("../../assets/images/36-basdai.png");
    }
    .basdai-37 {
      content: url("../../assets/images/37-basdai.png");
    }
    .basdai50-38 {
      content: url("../../assets/images/38-basdai50.png");
    }
    .crp-asdas-40 {
      content: url("../../assets/images/40-asdas-crp.png");
    }
    .safety-42 {
      content: url("../../assets/images/42-safety.png");
    }
    .safety-43 {
      content: url("../../assets/images/43-safety.png");
    }

    .exposure-44 {
      content: url("../../assets/images/44-exposure.png");
    }
    .exposure-45 {
      content: url("../../assets/images/45-exposure.png");
    }
    .multicenter-48 {
      content: url("../../assets/images/48-multicenter.png");
    }

    .multicenter-49 {
      content: url("../../assets/images/49-multicenter.png");
    }

    .endpoints-51 {
      content: url("../../assets/images/51-endpoints.png");
    }

    .treatment-53 {
      content: url("../../assets/images/53-treatment.png");
    }
    .treatment-54 {
      content: url("../../assets/images/54-treatment.png");
    }
    .pooled-56 {
      content: url("../../assets/images/56-pooled.png");
    }

    .pooled-safety-57 {
      content: url("../../assets/images/57-pooled-safety.png");
    }
  }
}
