$darkgreen: #359942;
$lightgreen: #6ebd44;
$darkblue: #003479;
$lightblue: #00a0df;

body {
  margin: 0px;
}
.App {
  width: 100%;
}
hr {
  padding-top: 6px;
  width: 90%;
  padding-bottom: 6px;
  margin: 0;
  border: none;
  width: 100%;
  /* Set the hr color */
  color: #333; /* old IE */
  //background color dark blue to light blue left ro right
  background-image: linear-gradient(to right, $darkblue, $lightblue);
}
* {
  font-family: Verdana;
}
h1,
h2,
h3 {
  font-family: Verdana;
}

h2 {
  color: $darkblue;
  font-size: 34px;
  margin-bottom: 0;
}

h3 {
  color: $darkblue;
  margin-left: 20px;
  font-size: 28px;
}
h4 {
  color: black;
  font-size: 20px;
  margin-top: 0;
}
h5 {
  color: black;
  font-size: 16px;
  font-weight: 0;
}
p {
  margin-top: 0;

  /* font-weight: bold; */
}

//isi

.content-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
  background: #f2f2f2;
  //make right look misty
  background-image: linear-gradient(to right, $darkblue, $lightblue);
}

button {
  cursor: pointer;
}
i,
.make-inline {
  display: inline !important;
  margin-bottom: unset !important;
  white-space: nowrap !important;
}

@media screen and (max-width: 719px) {
  .content-scroll {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

    overflow-y: hidden;
    overflow-x: scroll;
    background: #f2f2f2;
  }
  // p {
  //   font-size: 14px;
  // }
}
