.TitleWithIcon,
.TitleWithoutIcon {
  h3 {
    margin-left: 0;
  }
  text-align: left;

  display: flex;
  justify-content: flex-start;

  img {
    width: 45px;
    height: 45px;
    display: none;
  }
  //   .icon-discover {
  //     content: url("../../assets/images/icon-discover.png");
  //     transform: translateY(20px);
  //   }
  //   .icon-voyage {
  //     content: url("../../assets/images/icon-voyage.png");
  //     transform: translateY(20px);
  //   }
  //   .icon-gus {
  //     content: url("../../assets/images/icon-gus.png");
  //     transform: translateY(20px);
  //   }
  //   .icon-efficacy {
  //     content: url("../../assets/images/icon-efficacy.png");
  //     transform: translateY(20px);
  //   }
  //   .icon-axial {
  //     content: url("../../assets/images/icon-axial.png");
  //     transform: translateY(20px);
  //   }
  //   .icon-safety {
  //     content: url("../../assets/images/icon-safety.png");
  //     transform: translateY(20px);
  //   }
}

.TitleWithIcon {
  margin-top: 20px;
}
.TitleWithoutIconHR {
  margin-top: 3rem;
}
.TitleWithoutIcon {
  h3 {
    margin-left: 0px !important;
    margin-bottom: 0;
    margin-top: 2rem;
    font-size: 22px;
  }
}
