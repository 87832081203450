$darkgreen: #7e2e7a;
$darkblue: #003479;
$lightblue: #00a0df;
.Tab {
  text-align: left;
  padding: 20px;
  font-size: 1.5rem;

  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tabcontent {
    border-radius: 5px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border: 1px solid $lightblue;
    padding: 1rem;
    padding-bottom: 2rem;
  }
  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
  .tabcontent {
    width: 100%;
  }
  .tabLinksWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 50px;
  }
  .tabLinks {
    height: 100%;
    padding: 0px 20px;
    width: 45%;
    border-radius: 5px;
    background-color: $darkgreen;
    border: 0 !important;
  }
  .tabLinksText {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }
  img {
  }
  .footnote {
    font-size: 12px;
  }
  .discover {
    width: 25%;
  }
  .fade-in {
    animation: fadeIn 0.5s;
    display: block;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fade-out {
    animation: fadeOut 0.5s;
    display: none;
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @media (max-width: 719px) {
    .tabLinksText {
      font-size: 14px;
    }
  }
}
