@use "../../styles.scss";

$darkgreen: #359942;
$lightgreen: #6ebd44;
$darkblue: #003479;
$lightblue: #00a0df;

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .menu_item {
    margin-left: 0px;
    font-size: 14px !important;
    position: relative;
    text-align: left;
    padding-top: 15px;
    min-height: 30px;
    padding-bottom: 15px;

    &:hover {
      text-decoration: underline !important;
    }
    //on hover underline
  }
  //make a tag aligned with image
  a {
    background-repeat: no-repeat;
    //background colour top to bottom lightgreen to #359942;
    background-image: linear-gradient(to bottom, $lightgreen, $darkgreen);
    color: white;
    border-radius: 8px;
    width: calc(33% - 40px);
    flex-direction: row;
    align-items: flex-end;
    text-decoration: none;
    font-weight: bold;

    //on hover text underline
    &:hover {
      text-decoration: underline !important;
    }
  }
  span {
    display: inline-block;
    vertical-align: sub;
    &:hover {
      text-decoration: underline;
    }
  }
  img {
    width: 50px;
    vertical-align: middle;
    margin-left: 10px;
  }
  .safety {
    // margin-left: 0px !important;
  }
  .menu_item--text {
    vertical-align: middle;
    // position: absolute;
    // //center the text
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    font-size: 14px;
    margin-left: 2rem;
    width: fit-content;
    // max-width: 120px;
  }
  .inline {
    display: inline;
  }

  //if under 440px size then make .menu flex-direction column
  @media only screen and (max-width: 719px) {
    flex-direction: column;
    margin: 0;
    .menu_item {
      margin: 10px;
      width: 95%;
      img {
        margin-left: 20px;
      }
    }
    a {
      // width: 100% !important;
    }
  }
}

/* .Discover {
  background-image: url(../../assets/images/icon-discover.png);
}
.Efficacy {
  background-image: url(../../assets/images/icon-efficacy.png);
}
.Axial {
  background-image: url(../../assets/images/icon-axial.png);
}

.Safety {
  background-image: url(../../assets/images/icon-safety.png);
}
.Voyage {
  background-image: url(../../assets/images/icon-voyage.png);
} */
