$darkgreen: #7e2e7a;
$darkblue: #003479;
$lightblue: #00a0df;
.Tab {
  text-align: left;
  padding: 20px;
  font-size: 1.5rem;

  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tabcontent {
    border-radius: 5px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border: 1px solid $lightblue;
  }
  //create fadein fadeout animation

  //mask image from right with 80% right position
  //mask image position

  img {
    // -webkit-mask-image: -webkit-gradient(
    //   linear,
    //   left top,
    //   left bottom,
    //   from(rgba(0, 0, 0, 1)),
    //   to(rgba(0, 0, 0, 0))
    // );
    // mask-image: linear-gradient(
    //   to bottom,
    //   rgba(0, 0, 0, 1),
    //   rgba(0, 0, 0, 0)
    // );

    width: 100%;
    max-width: none;

    //make right hand side of image look blurred
  }
  .img_wrapper {
    //at image sizes below 720
    @media (max-width: 719px) {
      &.img_wrapper_clicked {
        mask-image: none !important;
      }
      overflow-x: scroll;
      img {
        width: auto;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
  .tabLinksWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 50px;
  }
  .tabLinks {
    min-height: 50px;

    height: 100%;
    padding: 0px 20px;
    width: 45%;
    border-radius: 16px;
    //make bottom not curved
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    background-color: $darkgreen;
  }
  .tabLinksText {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }

  img {
  }
  .footnote {
    font-size: 12px;
  }
  .discover {
    width: 25%;
  }
  @media (max-width: 719px) {
    .tabLinksText {
      font-size: 14px;
    }
  }
}
@media (max-width: 719px) {
  .tabLinks {
    font-size: 14px;
  }
}

#TabPasi {
  .tabLinks {
    width: 24%;
  }
}
