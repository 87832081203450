.TitleBlockBackground {
  background-image: url(../../assets/images/header-background.jpg);
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  h2 {
    margin-left: 20px;
  }
  p {
    margin-left: 20px;
    margin-right: 20px;
    font-size:20px;
  }
}
